import about from "./languages/pages/about";
import aml from "./languages/pages/aml";
import banking from "./languages/pages/banking";
import bitcoin from "./languages/pages/bitcoin";
import bonus from "./languages/pages/bonus";
import cookie from "./languages/pages/cookie";
import faq from "./languages/pages/faq";
import privacy from "./languages/pages/privacy";
import responsible from "./languages/pages/responsible";
import security from "./languages/pages/security";
import support from "./languages/pages/support";
import terms from "./languages/pages/terms";
import vip from "./languages/pages/vip";

// about.en.title = "Merge Works ( ͡° ͜ʖ ͡°)"

export const casino_contents = {
    about,
    aml,
    banking,
    bitcoin,
    bonus,
    cookie,
    faq,
    privacy,
    responsible,
    security,
    support,
    terms,
    vip,
}

export const promos = {
    ////////////////////////////////////////////////////////////////////////////////

    bonus_slider_data: {
        en: [
            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / contain",  button: {style:{} ,text: "Play now"}, image:{ src:"/brand/images/bonus_images/1en.svg"}},
            {background:"url(/brand/images/bonus_images/2.png) no-repeat center center / contain",  button: { text: "Play now"},          image:{ src:"/brand/images/bonus_images/1en.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / contain" , button: { text: "Play now"},          image:{ src:"/brand/images/bonus_images/1en.svg"}},

        ],

        de: [
            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / contain",  button: { text: "JETZT SPIELEN"}, image:{ src:"/brand/images/bonus_images/1de.svg"}},
            {background:"url(/brand/images/bonus_images/2.png) no-repeat center center / contain",  button: { text: "JETZT SPIELEN"}, image:{ src:"/brand/images/bonus_images/1de.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / contain",  button: { text: "JETZT SPIELEN"}, image:{ src:"/brand/images/bonus_images/1de.svg"}},

        ],
        fr: [
            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / contain",  button: { text: "JOUEZ MAINTENANT"}, image:{ src:"/brand/images/bonus_images/1fr.svg"}},
            {background:"url(/brand/images/bonus_images/2.png) no-repeat center center / contain",  button: { text: "JOUEZ MAINTENANT"}, image:{ src:"/brand/images/bonus_images/1fr.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / contain",  button: { text: "JOUEZ MAINTENANT"}, image:{ src:"/brand/images/bonus_images/1fr.svg"}},

        ],
        it: [
            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / contain",  button: { text: "GIOCA ORA"}, image:{ src:"/brand/images/bonus_images/1it.svg"}},
            {background:"url(/brand/images/bonus_images/2.png) no-repeat center center / contain",  button: { text: "GIOCA ORA"}, image:{ src:"/brand/images/bonus_images/1it.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / contain",  button: { text: "GIOCA ORA"}, image:{ src:"/brand/images/bonus_images/1it.svg"}},

        ],
        es: [
            {background:"url(/brand/images/bonus_images/1.png) no-repeat center center / contain",  button: { text: "JUEGUE YA"}, image:{ src:"/brand/images/bonus_images/1es.svg"}},
            {background:"url(/brand/images/bonus_images/2.png) no-repeat center center / contain",  button: { text: "JUEGUE YA"}, image:{ src:"/brand/images/bonus_images/1es.svg"}},
            {background:"url(/brand/images/bonus_images/3.png) no-repeat center center / contain",  button: { text: "JUEGUE YA"}, image:{ src:"/brand/images/bonus_images/1es.svg"}},

        ],
    },
    ////////////////////////////////////////////////////////////////////////////////
    promo_data: [
        {
            category: 1,
            name: {
                en: "Welcome Bonus",
                de: "WILLKOMMENSBONUS",
                es: "BONO DE BIENVENIDA",
                fr: "BONUS DE BIENVENUE",
                it: 'BONUS DI BENVENUTO',
            },


            bonus_details: {
                en: "400% WEEKLY PROMOTIONS AND SPECIALS",
                de: "400% WILLKOMMENSPAKET",
                fr: "400% BONUS DE BIENVENUE",
                es: "400% PAQUETE DE BIENVENIDA",
                it: '400% PACCHETTO DI BENVENUTO'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>DEPOSIT BONUS</h2>
                    <p>Your first deposit will be matched with 400% WELCOME BONUS</p>
                    <p>Here at 21 Grand Casino you can play with two types of depositing incentives. Deposit bonuses or Cashback Insurance.</p>
                    <p>Deposit bonuses are a bonus given to a player along with their deposit. Deposit bonuses are in essence a match bonus. Whatever amount you deposit, the casino will match. The higher the deposit amount, the higher your match bonus will be.</p>
                    <p>Click <a href="/terms">here</a> for the terms and conditions of this bonus.</p> </>,
                de: <>
                    <h2>EINZAHLUNGSBONUS</h2>
                    <p>Auf Ihre erste Einzahlung erhalten Sie einen 400% WILLKOMMENSBONUS.</p>
                    <p>Hier bei 21 Grand haben Sie zwei Optionen, um mit Einzahlungsangeboten zu spielen. Entweder mit einem Einzahlungsbonus oder mit einer Cashback- Versicherung.</p>
                    <p>Einzahungsboni sind Boni, die einem Spieler auf die Einzahlung angerechnet werden. Unabhängig davon, welchen Betrag Sie einzahlen, wird die Spielbank die Prozente des Bonus direkt anrechnen. Je höher der Einzahlungsbetrag, desto höher ist der gutgeschriebene Bonusbetrag.</p>
                    <p>Klicken Sie <a href="/terms">hier</a> für die Bonusbedingungen dieses Bonus.</p></>,
                fr: <><h2>BONUS SUR DÉPÔT</h2>
                    <p>Sur votre premier dépôt obtenez un BONUS DE BIENVENUE DE 400% !</p>
                    <p>Exemple: Déposez 100€ et jouez avec 500€ !</p>
                    <p>Sur 21 Grand Casino, vous pouvez jouer avec 3 types d'offres sur chacun de vos dépôts.</p>
                    <p>Bonus avec ou sans exigences ou Assurance perte Cashback. Pour toute information supplémentaire un agent de l'assistance chat en ligne se tient à votre entière disposition 24/7.</p>
                    <p>Cliquez <a href="/terms">ici</a> pour les termes et conditions de ce bonus </p> </>,
                es: <><h2>BONO DE DEPÓSITO</h2>
                    <p>Su primer depósito será compensado con un BONO DE BIENVENIDA DEL 400%.</p>
                    <p>Aquí en 21 Grand Casino puedes jugar con dos tipos de incentivos de depósito. Bonos de depósito o seguro de reembolso de dinero.</p>
                    <p>Los bonos de depósito son bonos otorgados a un jugador junto con su depósito. Los bonos de depósito son en esencia un bono de compensación. Cualquier cantidad que deposite, el casino la igualará. Cuanto mayor sea el monto del depósito, mayor será su bonificación.</p>
                    <p> Haga clic <a href="/terms">aquí</a> para ver los términos y condiciones de este bono.</p> </>,
                it: <><h2>BONUS DI DEPOSITO</h2>
                    <p>Il tuo primo deposito sarà abbinato al BONUS DI BENVENUTO DEL 400%.</p>
                    <p>Qui al 21 Grand Casino puoi giocare con due tipi di incentivi col deposito. Bonus di deposito o assicurazione di rimborso.</p>
                    <p>I bonus di deposito sono un bonus dato a un giocatore combinato col deposito. I bonus di deposito sono essenzialmente un bonus di partita. Qualunque importo tu depositi, il casinò corrisponderà. Maggiore è l'importo del deposito, maggiore sarà il bonus della tua partita.</p>,
                    <p>Clicca<a href="/terms">qui</a> per i termini e le condizioni di questo bonus</p> </>
            }
        },
        {

            name: {
                en: "Welcome Bonus",
                de: "WILLKOMMENSBONUS",
                es: "BONO DE BIENVENIDA",
                fr: "BONUS DE BIENVENUE",
                it: 'BONUS DI BENVENUTO',
            },

            bonus_details: {
                en: "100% CASHBACK INSURANCE",
                de: "100% CASHBACK-VERSICHERUNG",
                fr: "200 % BONUS SANS EXIGENCES",
                es: "100% SEGURO DE REEMBOLSO",
                it: '100% ASSICURAZIONE DI RIMBORSO'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>CASHBACK INSURANCE</h2>
                    <p>Cashback insurance is just as it sounds. Insurance if the house win. Feel confident as you play knowing that your money is guaranteed. Just speak with a support agent once your balance has finished, and they will add your real cashback to your account.</p>

                </>,
                de: <>
                    <p>CASHBACK-VERSICHERUNG</p>
                    <p>Die Cashback-Versicherung ist genau das, wonach es sich anhört. Sollte das Haus gewinnen, greift die Versicherung. Sie brauchen sich beim Spielen keine Sorgen machen, denn Sie wissen, dass Ihr Geld abgesichert ist. Sprechen Sie einfach mit einem Support-Mitarbeiter, sobald Ihr Guthaben aufgebraucht ist, und er wird Ihnen die Cashback-Versicherung in der Kasse hinterlegen.</p>
                </>,
                fr: <>
                    <h2>200% BONUS SANS EXIGENCES</h2>
                    <p>21 Grand casino propose à ses nouveaux joueurs un Méga bonus sans exigences de mises de 200% !</p>
                    <p>Exemple: déposez 100€ et jouez avec 300€, une fois le bonus et dépôt joué une fois, vous pouvez encaissez votre solde à tout moment, seul le montant du bonus vous sera déduit du gain final !</p>
                </>,
                es: <><h2>SEGURO DE REEMBOLSO</h2>
                    <p>El seguro de reembolso de efectivo es tal como suena. Seguro si la casa gana. Siéntase tranquilo mientras juega sabiendo que su dinero está garantizado. Simplemente hable con un representante de soporte una vez que su saldo haya finalizado, y se le agregará el reembolso real a su cuenta.</p>
                </>,
                it: <><h2>ASSICURAZIONE CASHBACK</h2>
                    <p>L'assicurazione cashback è come sembra. L'assicurazione, in caso vincesse il banco. Sentiti sicuro mentre giochi sapendo che i tuoi soldi sono garantiti. Parla con un rappresentante della chat una volta terminato il saldo e aggiungeranno il tuo vero cashback al tuo conto.</p>
                </>,
            }
        },
        {

            name: {
                fr: "BONUS DE BIENVENUE",
            },

            bonus_details: {
                fr: "100% D'ASSURANCE CASHBACK",
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                fr: <>
                    <h2>D'ASSURANCE CASHBACK</h2>
                    <p>Jouez tête reposée, 21 Grand Casino assure vos arrières !</p>
                    <p>L'assurance 100% Cashback est une assurance en cas de perte sur votre dépôt.</p>
                    <p>Jouez en toute sécurité, votre argent est garanti. Si vous ne faites aucun gain, réclamer à un agent de l'assistance chat en ligne votre Assurance 100% CashBack pour recevoir en CASH l'intégralité de votre dépot !</p>
                    <p>(Le 100% cashback doit être joué 1 fois et a une limite de retrait de 3 fois maximum)</p>
                    <p>Pour toute information supplémentaire un agent de l'assistance chat en ligne se tient à votre entière disposition 24/7.</p>
                </>,
            }
        },

        {

            name: {
                en: "WEEKLY PROMOTIONS",
                de: "WÖCHENTLICHE AKTIONEN",
                es: "OFERTAS SEMANALES",
                fr: "PROMOTIONS HEBDOMADAIRES",
                it: 'PROMOZIONI SETTIMANALI',
            },

            bonus_details: {
                en: "RAFFLE",
                de: "VERLOSUNG",
                fr: "TOURNOIS",
                es: 'SORTEO',
                it: 'LOTTERIA'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>RAFFLE</h2>
                    <p>Join one of our weekly raffles. Every deposit of 25 earns one raffle ticket. The more tickets you earn, the better your chances of winnings a cash prize.</p>
                </>,

                de: <>
                    <h2>VERLOSUNG</h2>
                    <p>Nehmen Sie an einer unserer wöchentlichen Verlosungen teil. Jede Einzahlung i.H.v. 25 erhält automatisch ein Los. Je mehr Lose Sie haben, desto besser stehen die Chancen auf den Gewinn in Cash.</p>
                </>,
                fr: <>
                    <h2>TOURNOIS HEBDOMADAIRES VIP À 50.000€</h2>
                    <p>Chaque semaine 21 Grand casino organise des tournois Vip avec 50.000€ de prix cash à partager !
                        Comment ça marche ?</p>
                    <p>Une fois inscrit (100€ cumulé ou pas), chaque euro misé est comptabilisé et vous rapporte 1 point (1€ parié = 1 point), et ces points sont mis en concurrence avec ceux des autres participants. Le joueur effectuant le plus de points repartira avec le premier prix qui est de 10.000€, le second 8.000€, etc... Tous les prix sont en cash et encaissables de suite.</p>
                    <p>Chaque matin le joueur reçoit son classement actualisé en fonction des points effectués.</p>
                    <p>Tous les jeux sont autorisés dans le calcul des points, les prix sont crédités en cash sur la base du classement final.</p>
                    <p>Voici les montants des prix: 25€, 50€, 75€, 100€ , 200€, 250€, 300€, 400€, 500€, 750€, 1.000€, 1.500€, 2.000€, 2.500€, 3.000€, 6.000€, 8.000 €, 10.000€</p>
                </>,
                es: <><h2>SORTEO</h2>
                    <p>Únase a uno de nuestros sorteos semanales. Con cada depósito de 25 gana un boleto de sorteo. Cuantos más boletos gane, mayores serán sus posibilidades de ganar un premio en efectivo.</p>

                </>,
                it: <><h2>LOTTERIA</h2>
                    <p>Partecipa a una delle nostre lotterie settimanali. Ogni deposito di 25 guadagna un biglietto della lotteria. Più biglietti guadagni, maggiori sono le possibilità di vincere un premio in denaro.</p>
                </>,
            }
        },
        {

            name: {
                en: "WEEKLY PROMOTIONS",
                de: "WÖCHENTLICHE AKTIONEN",
                es: "OFERTAS SEMANALES",
                fr: "PROMOTIONS HEBDOMADAIRES",
                it: 'PROMOZIONI SETTIMANALI',
            },

            bonus_details: {
                en: "CASHTRAVAGANZA",
                de: "CASHPARTY",
                fr: "LOTERIE",
                es: 'FIESTA DE CASH',
                it: 'CASHPARTY'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>CASHTRAVAGANZA</h2>
                    <p>Discover the craziness of our Cashparty special. You get a guarantee of up to 80% on all your deposits during the Cashparty period. Next day you get cash right in your account.</p>
                </>,

                de: <>
                    <h2>CASHPARTY</h2>
                    <p>Entdecken Sie die Vorzüge unseres Cashparty-Specials. Dabei erhalten Sie eine Garantie von bis zu 80% auf all Ihre Einzahlungen während des Cashparty-Zeitraums. Am nächsten Tag erhalten Sie Ihr Bargeld direkt zurück in Ihre Kasse.</p>
                </>,
                fr: <>
                    <h2>LOTERIE VIP À 50.000€</h2>
                    <p>21 Grand casino propose chaque mois à ses joueurs des loteries Vip avec 50.000€ de prix cash à partager !</p>
                    <p>Aucune inscription est requise, connectez vous et jouez durant la période de la promotion et sur chaque dépôt vous obtiendrez un ou plusieurs tickets de loterie.</p>
                    <p>Plus vous gagnez de tickets, meilleures sont vos chances de gagner un prix en cash. Les montants des tickets sont de</p>
                    <p>10 / 25 / 50 / 100 / 250 / 500 / 1.000 / 2.000 / 3.000 / 5.000€.</p>
                </>,
                es: <><h2>FIESTA DE CASH</h2>
                    <p>Descubra la locura de nuestra especial ¨Fiesta de Chash¨. Obtenga una garantía de hasta el 80% en todos sus depósitos durante el período de ¨Fiesta de Cash¨. Al día siguiente, obtendrá el efectivo en su cuenta.</p>

                </>,
                it: <><h2>CASHPARTY</h2>
                    <p>Scopri la follia del nostro speciale Cashparty. Ottieni una garanzia fino all'80% su tutti i tuoi depositi durante il periodo di Cashparty. Il giorno successivo ricevi denaro direttamente sul tuo conto.</p>
                </>,
            }
        },
        {
            name: {
                en: "WEEKLY PROMOTIONS",
                de: "WÖCHENTLICHE AKTIONEN",
                es: "OFERTAS SEMANALES",
                fr: "PROMOTIONS HEBDOMADAIRES",
                it: 'PROMOZIONI SETTIMANALI',
            },

            bonus_details: {
                en: "COMP POINTS",
                de: "TREUEPUNKTE",
                fr: "JOUEZ-ENCAISSEZ",
                es: 'PUNTOS DE FIDELIDAD O PUNTOS COMP',
                it: 'PUNTI COMP'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>COMP POINTS</h2>
                    <p>Get your comp points. All depositing and eligible players and claim their comp points on real money wagered. The comp point rate is 1 comp point per €1 wagered where 100000 comp points can be converted into €100.</p>
                </>,

                de: <>
                    <h2>TREUEPUNKTE</h2>
                    <p>Holen Sie sich Ihre Treuepunkte. Alle einzahlenden und berechtigten Spieler können Ihre Treuepunkte auf gewettetes Echtgeld anrechnen lassen. Die Treuepunkt-Rate beträgt 1Punkt pro 1€ Einsatz, wobei 100.000 Comp-Punkte in 100€ umgewandelt werden können.</p>
                </>,
                fr: <>
                    <h2>JOUEZ-ENCAISSEZ</h2>
                    <p>21 Grand casino propose chaque mois à ses joueurs des Jouez et Encaissez.</p>
                    <p>Comment ça marche ?</p>
                    <p>Une fois inscrit (100€ cumulé ou pas), sur chaque tranche de 1000 points réalisée sur la base de: 1€ parié = 1 point, le joueur reçoit en retour a l'issue de la promotion 10€ CASH, en plus des offres Vip sur chaque dépôt !</p>
                    <p>Exemple: si un joueur réalise 50,000 points, il recevra 500€ de prix en Cash encaissable à l'issue du Jouez et Encaissez.</p>
                </>,
                es: <><h2>PUNTOS DE FIDELIDAD O PUNTOS COMP</h2>
                    <p>Obtenga sus puntos de fidelidad. Todos los jugadores son elegibles, que depositan y reclaman sus puntos de fidelidad con dinero real apostado. La tasa de puntos de fidelidad es de 1 punto comp por cada € 1 apostado donde 100000 puntos comp se pueden convertir en €100.</p>

                </>,
                it: <><h2>PUNTI COMP</h2>
                    <p>Ottieni i tuoi punti comp. Tutti i giocatori depositanti e idonei possono richiedere i loro punti comp su denaro reale scommesso. La percentuale di punti comp è 1 punto comp per $ 1 scommessi dove 100000 punti comp possono essere convertiti in $ 100.</p>
                </>,
            }
        },
        {
            name: {
                fr: "PROMOTIONS HEBDOMADAIRES",

            },
            bonus_details: {
                fr: "WEEKY CASHBACK",
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                fr: <>
                    <h2>WEEKY CASHBACK</h2>
                    <p>Le WEEKY-CASHBACK consiste à vous donner, en plus des offres sur chaque dépôt, une Assurance perte Globale de tous les dépôts réalisés durant la promotion, si aucun gain n'as été réalisé durant cette période. Voici en détail le pourcentage de l'assurance globale:</p>
                    <p>100€+ = 15%</p>
                    <p>200€+ = 20%</p>
                    <p>500€+ = 25%</p>
                    <p>1000€+ = 30%</p>
                </>,
            }
        },
        {
            name: {
                fr: "PROMOTIONS HEBDOMADAIRES",

            },
            bonus_details: {
                fr: "POINTS DE FIDÉLITÉS",
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                fr: <>
                    <h2>POINTS DE FIDÉLITÉ</h2>
                    <p>Les joueurs ayant effectué un dépôt sont éligibles et reçoivent des points de fidélités sur chaque session de jeu.</p>
                    <p>Vous pouvez les convertir vous même en argent réel sur votre compte.</p>
                    <p>Le taux des points de fidélités est de 1 point chaque 1€ parié, 100.000 points de fidélités représentent 100€ cash d'argent réel.</p>
                </>,
            }
        },

    ],
    ////////////////////////////////////////////////////////////////////////////////
    jackpot: [
        {
            name: "Jim S. won",
            value: "$11,910.14",
            desc: "on at the Strike Gold "
        },
        {
            name: "Eve G. won",
            value: "$1.733.00",
            desc: "on at the copa "
        },
        {
            name: "Jordan W. won ",
            value: "$5,235.60",
            desc: "on at the Major Moolah "
        },
        {
            name: "John H. won",
            value: "$856.00",
            desc: "on at the Catsino "
        },
        {
            name: "Pam T. won ",
            value: "$29,990.58",
            desc: "on at the Money Magic"
        },
    ]
    ////////////////////////////////////////////////////////////////////////////////
}

export default casino_contents





